import type { PostProcessorModule } from "i18next";
import pangu from "pangu";

const PanguMiddleware: PostProcessorModule = {
    type: "postProcessor",
    name: "pangu",
    process: (value) => pangu.spacing(value),
};

export default PanguMiddleware;
