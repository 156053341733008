import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useCheckStatusQuery } from "store/services/api";

const NoAuthRoute: React.FC<RouteProps> = (props) => {
    const { data } = useCheckStatusQuery();

    return data && data.RoleLevel ? <Redirect to={"/"} /> : <Route {...props} />;
};

export default NoAuthRoute;
