import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import viewUpdate from "./reducers/viewUpdate";
import thunk from "redux-thunk";
import api from "./services/api";
import uploader from "./reducers/uploader";
import photoManager from "./reducers/photoManager";

const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        viewUpdate,
        uploader,
        photoManager,
    },
    preloadedState: {},
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(thunk).concat(api.middleware),
    devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const { dispatch } = store;

export default store;
