import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImgDay } from "model/response/imgDaysResponse";
import ImageData from "model/response/imageUploadInfo";

interface UploaderState {
    imgDay: ImgDay | null;
    classification: string[];
    source: number | null;
    images: ImageData[];
}

const initialState: UploaderState = {
    imgDay: null,
    classification: [],
    source: null,
    images: [],
};

const uploader = createSlice({
    name: "uploader",
    initialState,
    reducers: {
        setImgDay(state, action: PayloadAction<ImgDay | null>) {
            state.imgDay = action.payload;
            console.log("ImgDay has been updated:", state.imgDay);
        },
        setClassification(state, action: PayloadAction<string[]>) {
            state.classification = action.payload;
            console.log("Classification has been updated:", state.classification);
        },
        setSource(state, action: PayloadAction<number>) {
            state.source = action.payload;
            console.log("Bucket has been updated:", state.source);
        },
        addImage(state, action: PayloadAction<ImageData>) {
            // 避免重复
            if (state.images.some((image) => image.imguid === action.payload.imguid)) {
                return;
            }

            state.images.push(action.payload);
        },
        addImages(state, action: PayloadAction<ImageData[]>) {
            state.images = state.images.concat(action.payload);
            // 去重
            state.images = Array.from(
                new Map<string, ImageData>(
                    state.images.map((image) => [image.imguid, image])
                ).values()
            );
        },
        deleteImage(state, action: PayloadAction<string>) {
            // 去重
            state.images = state.images.filter((image) => image.imguid !== action.payload);
        },
    },
});

export const { setImgDay, setClassification, setSource, addImage, addImages, deleteImage } =
    uploader.actions;
export default uploader.reducer;
