import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import qs from "qs";
import axios from "middleware/Axios";
import type { AxiosError, AxiosRequestConfig } from "axios";
import type Response from "model/response/response";
import type WebInfo from "model/response/webInfo";
import type StatusResponse from "model/response/statusResponse";
import type UploadResponse from "model/response/uploadResponse";
import type ImgDaysResponse from "model/response/imgDaysResponse";
import type UserKeyList from "model/response/userKeyList";
import type ClassificationListResponse from "model/response/classificationListResponse";
import type ImageUploadInfo from "model/response/imageUploadInfo";
import type ImageUrlUploadPayload from "model/request/imageUrlUploadPayload";
import type LoginPayload from "model/request/loginPayload";
import type LoginResponse from "model/response/loginResponse";
import type ImageDeletePayload from "model/request/imageDeletePayload";
import type BatchImagesResponse from "model/response/batchImagesResponse";
import type RegisterPayload from "model/request/registerPayload";
import type OverviewDataResponse from "model/response/admin/overviewDataResponse";
import type RecentlyDataResponse from "model/response/admin/recentlyDataResponse";
import type StatDataResponse from "model/response/admin/statDataResponse";
import type SystemVersionResponse from "model/response/root/systemVersionResponse";
import type ChartResponse from "model/response/admin/chartResponse";
import type ChartPayload from "model/request/admin/chartPayload";
import type SelectPhotoResponse from "model/response/admin/selectPhotoResponse";
import type SelectPhotoPayload from "model/request/admin/selectPhotoPayload";
import type UserData from "model/base/userData";
import type StorageListRequest from "model/response/admin/storageListRequest";
import type ClassificationData from "model/base/classificationData";
import type DeleteProgressData from "model/response/admin/deleteProgressData";
import type AlbumImageListResponse from "model/response/albumImageListResponse";
import type SaveAlbumPayload from "model/request/admin/saveAlbumPayload";
import type SaveAlbumResponse from "model/response/admin/saveAlbumResponse";
import type GeneratePanoramaPayload from "model/request/admin/generatePanoramaPayload";
import type GeneratePanoramaResponse from "model/response/admin/generatePanoramaResponse";
import type MoveImageClassificationPayload from "model/request/admin/moveImageClassificationPayload";
import type GalleryListPayload from "model/request/admin/galleryListPayload";
import type GalleryListResponse from "model/response/admin/galleryListResponse";
import type ImgPageResponse from "model/response/imgPageResponse";
import type ImageCollectionPayload from "model/request/imageCollectionPayload";

const axiosBaseQuery: BaseQueryFn<{
    url: string;
    method?: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    headers?: AxiosRequestConfig["headers"];
}> = async ({ url, method, data, params, headers }) => {
    const codeAccepted = [0, 100, 200, 40040];

    try {
        const result = await axios.request<Response>({
            url: url,
            method: method || "POST",
            data: data,
            params: params,
            headers: headers,
        });

        const code = parseInt(result.data.code);
        if (!isNaN(code) && !codeAccepted.includes(code)) {
            return {
                error: {
                    status: code,
                    data: result.data.data,
                    message: result.data.exceptions || result.data.info,
                },
            };
        }

        switch (url) {
            case "/user/login":
                return { data: result.data };
            default:
                return { data: result.data.data };
        }
    } catch (axiosError) {
        let err = axiosError as AxiosError<Response>;
        return {
            error: {
                status: err.response?.status,
                data: err.response?.data.exceptions || err.response?.data.info || err.message,
            },
        };
    }
};

export const tagTypes = [
    "Status",
    "UploadInfo",
    "ImgDays",
    "Storage",
    "Classification",
    "User",
    "OverviewData",
    "Images",
    "ImagesShortCode",
    "Gallery",
];

const api = createApi({
    reducerPath: "api",
    baseQuery: axiosBaseQuery,
    tagTypes: ["WebInfo", ...tagTypes],
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getWebInfo: builder.query<WebInfo, void>({
            query: () => ({
                url: "/webInfo",
                method: "GET",
            }),
            providesTags: ["WebInfo"],
        }),
        checkStatus: builder.query<StatusResponse, void>({
            query: () => ({
                url: "/checkStatus",
            }),
            transformResponse(response: StatusResponse) {
                return response;
            },
            providesTags: ["Status"],
        }),
        getUploadInfo: builder.query<UploadResponse, void>({
            query: () => ({
                url: "/getUploadInfo",
            }),
            providesTags: ["UploadInfo"],
        }),
        getImgDays: builder.query<ImgDaysResponse, void>({
            query: () => ({
                url: "/getImgDays",
            }),
            providesTags: ["ImgDays"],
        }),
        getUserKeyList: builder.query<UserKeyList, void>({
            query: () => ({
                url: "/getUserKeyList",
            }),
            providesTags: ["Storage"],
        }),
        getClassification: builder.query<ClassificationListResponse, void>({
            query: () => ({
                url: "/getClassification",
            }),
            providesTags: ["Classification"],
        }),
        uploadFile: builder.mutation<ImageUploadInfo, FormData>({
            query: (data) => ({
                url: "/upload",
                data: data,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }),
            invalidatesTags: () => ["Classification", "Images", "ImagesShortCode"],
        }),
        uploadUrlFile: builder.mutation<BatchImagesResponse, ImageUrlUploadPayload>({
            query: (data) => ({
                url: "/uploadForUrl",
                data: qs.stringify({ data: JSON.stringify(data) }),
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Classification", id: arg.classifications },
                "Images",
                "ImagesShortCode",
            ],
        }),
        deleteImage: builder.mutation<boolean, ImageDeletePayload>({
            query: (data) => ({
                url: "/deleImagesByUid",
                data: qs.stringify({ data: JSON.stringify(data) }),
            }),
            transformResponse(response: number[]) {
                return response.length > 0;
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Images", id: arg.imguid },
                "ImagesShortCode",
            ],
        }),
        login: builder.mutation<Response<LoginResponse>, LoginPayload>({
            query: (data) => ({
                url: "/user/login",
                data: qs.stringify({ data: JSON.stringify(data) }),
            }),
            invalidatesTags: tagTypes,
            transformResponse: (response: Response<LoginResponse>) => {
                if (response.data) {
                    localStorage.setItem("Authorization", response.data.token);
                }
                return response;
            },
        }),
        logout: builder.mutation<Response<null>, void>({
            query: () => ({
                url: "/user/logout",
                method: "POST",
            }),
            invalidatesTags: tagTypes,
            transformResponse: (response: Response<null>) => {
                localStorage.removeItem("Authorization");
                return response;
            },
        }),
        register: builder.mutation<null, RegisterPayload>({
            query: (data) => ({
                url: "/user/register",
                method: "POST",
                data: qs.stringify({ data: JSON.stringify(data) }),
            }),
            invalidatesTags: tagTypes,
        }),
        getOverviewData: builder.query<OverviewDataResponse, void>({
            query: () => ({ url: "/admin/overviewData", method: "POST" }),
            providesTags: ["OverviewData"],
        }),
        getRecently: builder.query<RecentlyDataResponse, void>({
            query: () => ({ url: "/admin/getRecently", method: "POST" }),
            providesTags: ["OverviewData"],
        }),
        getStat: builder.query<StatDataResponse, void>({
            query: () => ({ url: "/admin/getYyyy", method: "POST" }),
            providesTags: ["OverviewData"],
        }),
        getChart: builder.query<ChartResponse, ChartPayload>({
            query: (data) => ({
                url: "/admin/getChart",
                data: qs.stringify({ data: JSON.stringify(data) }),
            }),
            providesTags: ["Images", "User", "OverviewData"],
        }),
        getSystemVersion: builder.query<SystemVersionResponse, void>({
            query: () => ({ url: "/admin/getYyyy", method: "POST" }),
        }),
        selectPhoto: builder.query<SelectPhotoResponse, SelectPhotoPayload>({
            query: (data) => ({
                url: "/admin/selectPhoto",
                data: qs.stringify({ data: JSON.stringify(data) }),
            }),
            providesTags: ["Images"],
        }),
        getUserInfo: builder.query<Pick<UserData, "username" | "email">, void>({
            query: () => ({ url: "/admin/getUserInfo" }),
            providesTags: ["User"],
        }),
        updateToken: builder.mutation<string, void>({
            query: () => ({
                url: "/admin/updateToken",
            }),
            invalidatesTags: ["OverviewData"],
        }),
        getStorageList: builder.query<StorageListRequest, void>({
            query: () => ({ url: "/admin/getStorageNameByUser" }),
            providesTags: ["Storage"],
        }),
        getCurrentClassification: builder.query<Array<ClassificationData>, string>({
            query: (arg) => ({
                url: "/admin/getCurrentClassification",
                data: qs.stringify({ data: JSON.stringify({ parentid: arg }) }),
            }),
            providesTags: (result, error, arg) => [{ type: "Classification", id: `path-${arg}` }],
        }),
        deleteImages: builder.mutation<null, number[]>({
            query: (args) => ({
                url: "/admin/deleImages",
                data: qs.stringify({ data: JSON.stringify({ images: args.join(",") }) }),
            }),
            invalidatesTags: (result, error, arg) =>
                arg.map((id) => ({ type: "Images", id })) || [],
        }),
        getDeleteProgress: builder.mutation<DeleteProgressData, void>({
            query: () => ({
                url: "/admin/GetDelprogress",
            }),
        }),
        getAlbumImageList: builder.query<AlbumImageListResponse, string[]>({
            query: (arg) => ({
                url: "/getAlbumImgList",
                data: qs.stringify({ data: JSON.stringify({ imguidlist: arg }) }),
            }),
        }),
        saveForAlbum: builder.mutation<SaveAlbumResponse, SaveAlbumPayload>({
            query: (arg) => ({
                url: "/SaveForAlbum",
                data: qs.stringify({ data: JSON.stringify(arg) }),
            }),
        }),
        generatePanorama: builder.mutation<GeneratePanoramaResponse, GeneratePanoramaPayload>({
            query: (arg) => ({
                url: "/admin/makeView360",
                data: qs.stringify({ data: JSON.stringify(arg) }),
            }),
        }),
        moveImageClassification: builder.mutation<null, MoveImageClassificationPayload>({
            query: (arg) => ({
                url: "/admin/moveImgClassification",
                data: qs.stringify({ data: JSON.stringify(arg) }),
            }),
        }),
        download: builder.mutation<null, string[]>({
            query: (arg) => ({
                url: "/admin/download",
                data: qs.stringify({ data: JSON.stringify({ images: arg }) }),
            }),
        }),
        getGalleryList: builder.query<GalleryListResponse, GalleryListPayload>({
            query: (arg) => ({
                url: "/admin/getGalleryList",
                data: qs.stringify({ data: JSON.stringify(arg) }),
            }),
            providesTags: (result) =>
                result?.list.map((galleryData) => ({
                    type: "Gallery",
                    id: galleryData.albumkey,
                })) || [],
        }),
        getImgPage: builder.query<ImgPageResponse, string>({
            query: (arg) => ({
                url: "/getImgPage",
                data: qs.stringify({ data: JSON.stringify({ shortCode: arg }) }),
            }),
            providesTags: (result, error, arg) => [{ type: "ImagesShortCode", id: arg }],
        }),
        addImageCollection: builder.mutation<null, ImageCollectionPayload>({
            query: (arg) => ({
                url: "/ImageCollection",
                data: qs.stringify({ data: JSON.stringify(arg) }),
            }),
            invalidatesTags: ["Images"],
        }),
        imgGreat: builder.mutation<null, string>({
            query: (arg) => ({
                url: "/imgGreat",
                data: qs.stringify({ data: JSON.stringify({ id: arg }) }),
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Images", id: arg }],
        }),
    }),
});

export const {
    useGetWebInfoQuery,
    useCheckStatusQuery,
    useGetImgDaysQuery,
    useGetUploadInfoQuery,
    useGetClassificationQuery,
    useGetUserKeyListQuery,
    useUploadFileMutation,
    useUploadUrlFileMutation,
    useDeleteImageMutation,
    useLoginMutation,
    useLogoutMutation,
    useRegisterMutation,
    useGetOverviewDataQuery,
    useGetChartQuery,
    useGetRecentlyQuery,
    useGetStatQuery,
    useGetSystemVersionQuery,
    useGetUserInfoQuery,
    useSelectPhotoQuery,
    useUpdateTokenMutation,
    useGetStorageListQuery,
    useGetCurrentClassificationQuery,
    useDeleteImagesMutation,
    useGetDeleteProgressMutation,
    useGetAlbumImageListQuery,
    useSaveForAlbumMutation,
    useMoveImageClassificationMutation,
    useDownloadMutation,
    useGetGalleryListQuery,
    useGeneratePanoramaMutation,
    useGetImgPageQuery,
    useImgGreatMutation,
    useAddImageCollectionMutation,
} = api;
export default api;
