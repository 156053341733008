import React, { useEffect } from "react";
import styles from "./App.module.scss";
import "styles/dialog_global.scss";
import {
    Backdrop,
    CircularProgress,
    CssBaseline,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import {
    useCheckStatusQuery,
    useGetClassificationQuery,
    useGetImgDaysQuery,
    useGetUploadInfoQuery,
    useGetUserKeyListQuery,
    useGetWebInfoQuery,
} from "store/services/api";
import { Switch } from "react-router-dom";
import { useAppSelector } from "store";
import { AuthRoute, CommonRoute, NoAuthRoute } from "middleware/Route";
import { useTranslation } from "react-i18next";
import Loadable from "./components/@extend/Loadable";
import Loading from "./components/@extend/Loading";
import axios from "./middleware/Axios";

const Home = Loadable(React.lazy(() => import("components/Frontend/Home")));
const Login = Loadable(React.lazy(() => import("components/Frontend/Login")));
const Register = Loadable(React.lazy(() => import("components/Frontend/Register")));
const Admin = Loadable(React.lazy(() => import("components/Admin")));
const NotFound = Loadable(React.lazy(() => import("components/NotFound")));
const ImagePreview = Loadable(React.lazy(() => import("components/Frontend/ImagePreview")));

const useBasicQuery = () => {
    const webInfo = useGetWebInfoQuery();
    const checkStatus = useCheckStatusQuery();
    const imgDays = useGetImgDaysQuery();
    const userKeyList = useGetUserKeyListQuery();
    const classification = useGetClassificationQuery();
    const uploadInfo = useGetUploadInfoQuery();

    return {
        data: {
            webInfo,
            checkStatus,
            imgDays,
            userKeyList,
            classification,
            uploadInfo,
        },
        isLoading:
            webInfo.isLoading ||
            checkStatus.isLoading ||
            imgDays.isLoading ||
            userKeyList.isLoading ||
            classification.isLoading ||
            uploadInfo.isLoading,
        isFetching:
            webInfo.isFetching ||
            checkStatus.isFetching ||
            imgDays.isFetching ||
            userKeyList.isFetching ||
            classification.isFetching ||
            uploadInfo.isFetching,
        isError:
            webInfo.isError ||
            checkStatus.isError ||
            imgDays.isError ||
            userKeyList.isError ||
            classification.isError ||
            uploadInfo.isError,
    };
};

const App: React.FC = () => {
    const { t } = useTranslation("title");
    const theme = useTheme();

    const {
        isLoading,
        data: {
            webInfo: { data: webInfo },
        },
    } = useBasicQuery();
    const title = useAppSelector((state) => state.viewUpdate.title);
    useEffect(() => {
        if (typeof webInfo !== "undefined") {
            if (title === null) {
                window.document.title = `${webInfo.webname} - ${webInfo.websubtitle}`;
            } else {
                window.document.title = `${t(title)} - ${webInfo.webname}`;
            }
        } else {
            window.document.title = title || t("loading");
        }
    }, [webInfo, title, t]);

    // real loading
    const [loading, setLoading] = React.useState(false);
    axios.interceptors.request.use((req) => {
        setLoading(true);
        return req;
    });
    axios.interceptors.response.use((res) => {
        setLoading(false);
        return res;
    });

    return (
        <React.Suspense>
            <CssBaseline />
            <Backdrop
                open={isLoading}
                className={styles.modal}
                sx={{
                    zIndex: theme.zIndex.modal + 1,
                }}
            >
                <Stack spacing={4} className={styles.modalBox}>
                    <CircularProgress disableShrink color={"info"} />
                    <Typography variant={"h6"} color={"white"} component={"div"}>
                        {t("title::loading")}
                    </Typography>
                </Stack>
            </Backdrop>
            {loading && <Loading />}

            <Switch>
                <CommonRoute path={"/"} exact>
                    <Home />
                </CommonRoute>

                <NoAuthRoute path={"/login"} exact>
                    <Login />
                </NoAuthRoute>
                {webInfo?.register === 1 && (
                    <NoAuthRoute path={"/register"} exact>
                        <Register />
                    </NoAuthRoute>
                )}

                <CommonRoute path={"/v/:id"} exact>
                    <ImagePreview />
                </CommonRoute>

                <AuthRoute path={"/admin"}>
                    <Admin />
                </AuthRoute>

                <CommonRoute path={"*"}>
                    <NotFound />
                </CommonRoute>
            </Switch>
        </React.Suspense>
    );
};

export default App;
