import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import { useAppSelector } from "store";
import { grey } from "@mui/material/colors";

const Wrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const mode = useAppSelector((state) => state.viewUpdate.mode);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    const theme = useMemo(() => {
        const darkMode = (mode === null && prefersDarkMode) || mode === "dark";

        return createTheme({
            palette: {
                background: {
                    default: darkMode ? grey[800] : grey[100],
                    paper: darkMode ? grey[900] : grey[50],
                },
                action: {
                    active: darkMode ? grey[300] : grey[800],
                },
                mode: darkMode ? "dark" : "light",
            },
        });
    }, [mode, prefersDarkMode]);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Wrapper;
