import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ImageData from "model/base/imageData";
import SelectPhotoResponse from "model/response/admin/selectPhotoResponse";

export interface PhotoManagerState {
    pathname: Array<{
        id: string;
        title: string;
    }>;
    selected: string[];
    inSearch: boolean;
    pageNum: number;
    pageSize: number;
    search: {
        type: "me" | "all";
        classifications: string[];
        bucket: number | null;
        user: {
            type: "0" | "1";
            name: string | null;
        };
        fromDate: string | null;
        toDate: string | null;
        violation: boolean;
    };
    dialogs: {
        imageData: ImageData | null;
        linkOpen: boolean;
        infoOpen: boolean;
    };
    imageData: SelectPhotoResponse;
}

const initialState: PhotoManagerState = {
    pathname: [],
    selected: [],
    inSearch: false,
    pageNum: 1,
    pageSize: 36,
    search: {
        type: "me",
        classifications: [],
        bucket: null,
        user: {
            type: "1",
            name: null,
        },
        fromDate: null,
        toDate: null,
        violation: false,
    },
    dialogs: {
        imageData: null,
        linkOpen: false,
        infoOpen: false,
    },
    imageData: { total: 0, rows: [] },
};

const photoManager = createSlice({
    name: "photoManager",
    initialState,
    reducers: {
        setSearch(state, action: PayloadAction<boolean>) {
            state.inSearch = action.payload;
        },
        setType(state, action: PayloadAction<string>) {
            if (action.payload === "me" || action.payload === "all") {
                state.search.type = action.payload;
            }
        },
        backPath(state) {
            state.selected = [];
            state.pathname.pop();
        },
        addPath(state, action: PayloadAction<{ id: string; title: string }>) {
            state.selected = [];
            state.pathname.push(action.payload);
        },
        goParentPath(state, action: PayloadAction<{ id: string; title: string }>) {
            state.selected = [];

            const position = state.pathname.indexOf(action.payload);
            state.pathname = state.pathname.slice(0, position);
        },
        clearPath(state) {
            state.selected = [];
            state.pathname = [];
        },
        addSelected(state, action: PayloadAction<string>) {
            if (state.selected.indexOf(action.payload) === -1) {
                state.selected.push(action.payload);
            }
        },
        removeSelected(state, action: PayloadAction<string>) {
            state.selected = state.selected.filter((id) => id !== action.payload);
        },
        setSelected(state, action: PayloadAction<string[]>) {
            state.selected = action.payload;
        },
        addClassification(state, action: PayloadAction<string>) {
            if (state.search.classifications.indexOf(action.payload) === -1) {
                state.search.classifications.push(action.payload);
            }
        },
        removeClassification(state, action: PayloadAction<string>) {
            while (state.search.classifications.indexOf(action.payload) !== -1) {
                state.search.classifications.splice(
                    state.search.classifications.indexOf(action.payload),
                    1
                );
            }
        },
        setClassification(state, action: PayloadAction<string[]>) {
            state.search.classifications = action.payload;
        },
        setBucket(state, action: PayloadAction<number | null>) {
            state.search.bucket = action.payload;
        },
        setUserType(state, action: PayloadAction<string>) {
            if (action.payload === "0" || action.payload === "1") {
                state.search.user.type = action.payload;
            }
        },
        setUserName(state, action: PayloadAction<string | null>) {
            state.search.user.name = action.payload;
        },
        setStartDate(state, action: PayloadAction<string | null>) {
            state.search.fromDate = action.payload;
        },
        setEndDate(state, action: PayloadAction<string | null>) {
            state.search.toDate = action.payload;
        },
        setViolation(state, action: PayloadAction<boolean>) {
            state.search.violation = action.payload;
        },
        setCurrentImage(state, action: PayloadAction<ImageData | null>) {
            state.dialogs.imageData = action.payload;
        },
        setLinkOpen(state, action: PayloadAction<boolean>) {
            state.dialogs.linkOpen = action.payload;
        },
        setInfoOpen(state, action: PayloadAction<boolean>) {
            state.dialogs.infoOpen = action.payload;
        },
        setPageNum(state, action: PayloadAction<number>) {
            state.pageNum = action.payload;
        },
        setImageData(state, action: PayloadAction<SelectPhotoResponse>) {
            state.imageData = action.payload;
        },
    },
});

export const {
    setType,
    backPath,
    addPath,
    goParentPath,
    clearPath,
    addSelected,
    removeSelected,
    setSelected,
    addClassification,
    removeClassification,
    setClassification,
    setEndDate,
    setStartDate,
    setBucket,
    setUserType,
    setUserName,
    setViolation,
    setCurrentImage,
    setInfoOpen,
    setLinkOpen,
    setSearch,
    setPageNum,
    setImageData,
} = photoManager.actions;
export default photoManager.reducer;
