import React, { FC, LazyExoticComponent } from "react";
import Loading from "./Loading";

export interface LoadableType<T = any> {
    (Component: LazyExoticComponent<FC<T>>): FC<T>;
}

const Loadable: LoadableType = (Component) => (props) =>
    (
        <React.Suspense fallback={<Loading />}>
            <Component {...props} />
        </React.Suspense>
    );

export default Loadable;
