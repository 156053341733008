import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useCheckStatusQuery } from "store/services/api";

const AuthRoute: React.FC<RouteProps> = (props) => {
    const { isError } = useCheckStatusQuery();

    return isError ? <Redirect to={"/login"} /> : <Route {...props} />;
};

export default AuthRoute;
