import axios from "axios";
import Config from "../../config";
import qs from "qs";

export const getBaseUrl = () => {
    if (process.env.NODE_ENV === "development") {
        return "/api/";
    }

    return Config.backend;
};

const instance = axios.create({
    baseURL: getBaseUrl(),
    withCredentials: true,
    responseEncoding: "utf8",
    responseType: "json",
    headers: {
        usersorigin: Config.userorigin,
        "Content-Type": "application/x-www-form-urlencoded",
    },
    data: qs.stringify({}),
});

// 添加JWT验证标头
instance.interceptors.request.use((request) => {
    const authorization = localStorage.getItem("Authorization");
    if (authorization !== null) {
        if (typeof request.headers === "undefined") {
            request.headers = {};
        }

        request.headers["Authorization"] = authorization;
    }
    return request;
});

// debugger;
if (process.env.NODE_ENV === "development") {
    instance.interceptors.response.use(
        (response) => {
            console.log("[Response]", "success", response);
            return response;
        },
        (error) => {
            console.log("[Response]", "error", error);
            return error;
        }
    );
}

export default instance;
