import { Resource } from "i18next";
import zhCn from "./zh-cn";
import enUs from "./en-us";

const translation: Resource = {
    "zh-CN": zhCn,
    "en-US": enUs,
};

export default translation;
