import type { ResourceLanguage } from "i18next";
import common from "./common.json";
import general from "./general.json";
import notice from "./notice.json";
import title from "./title.json";
import menu from "./menu.json";

export default {
    common,
    general,
    notice,
    title,
    menu,
} as ResourceLanguage;
