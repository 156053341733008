import React from "react";
import { LinearProgress, styled } from "@mui/material";

const LoaderWrapper = styled("div")(({ theme }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2001,
    width: "100%",
    "& > * + *": {
        marginTop: theme.spacing(2),
    },
}));

const Loading: React.FC = () => (
    <LoaderWrapper key={"loading"}>
        <LinearProgress key={"loading-line"} />
    </LoaderWrapper>
);

export default Loading;
