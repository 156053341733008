import i18next from "i18next";
import I18NextLocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import translation from "./locales";
import PanguMiddleware from "./middleware/panguMiddleware";

const i18n = i18next.createInstance({
    load: "currentOnly",
    resources: translation,
    nsSeparator: "::",
    backend: {
        backends: [I18NextLocalStorageBackend],
        backendOptions: [
            {
                expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
            },
        ],
    },
    defaultNS: "common",
    fallbackNS: ["common", "notice"],
    saveMissing: process.env.NODE_ENV === "development",
    debug: process.env.NODE_ENV === "development",
});

i18n.use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .use(PanguMiddleware)
    .init({
        debug: process.env.NODE_ENV === "development",
        saveMissing: process.env.NODE_ENV === "development",
        postProcess: ["pangu"],
    });

export const languageName: Record<keyof typeof translation, string> = {
    "zh-CN": "简体中文（中国）",
    "en-US": "English (US)",
};

export default i18n;
