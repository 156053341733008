import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./service-worker-register";
import { Provider } from "react-redux";
import store from "store";
import { AppThemeProvider } from "./theme";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "i18n";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <AppThemeProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <SnackbarProvider
                            anchorOrigin={{
                                horizontal: "right",
                                vertical: "top",
                            }}
                            autoHideDuration={2000}
                        >
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </AppThemeProvider>
            </I18nextProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (process.env.NODE_ENV !== "development") {
    serviceWorkerRegistration.register({
        onSuccess(registration: ServiceWorkerRegistration) {
            console.log("ServiceWorker registration successful with scope: ", registration.scope);
        },
        onUpdate(registration: ServiceWorkerRegistration) {
            console.log("ServiceWorker registration updated with scope: ", registration.scope);
            alert(i18n.t("notice::serviceWorker.update"));
            if (registration && registration.waiting) {
                registration.waiting.postMessage({ type: "SKIP_WAITING" });
            }
            window.location.reload();
        },
    });
}
