import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ViewUpdateState {
    title: string | null;
    mode: "light" | "dark" | null;
}

const initialState: ViewUpdateState = {
    title: null,
    mode:
        localStorage.getItem("mode") === "dark"
            ? "dark"
            : localStorage.getItem("mode") === "light"
            ? "light"
            : null,
};

const viewUpdate = createSlice({
    name: "viewUpdate",
    initialState,
    reducers: {
        setTitle(state, action: PayloadAction<string>) {
            state.title = action.payload;
        },
        setMode(state, action: PayloadAction<"light" | "dark">) {
            state.mode = action.payload;
            localStorage.setItem("mode", action.payload);
        },
    },
});

export const { setTitle, setMode } = viewUpdate.actions;
export default viewUpdate.reducer;
